/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, ReactNode, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthentication } from './authentication';
import { useAlert } from './alert';
import { useApi } from './api';
import { AppPermission } from '../types';

interface UserContext {
  permissions?: AppPermission[];
  loading: boolean;
}

const initialState: UserContext = {
  permissions: undefined,
  loading: false,
};

const UserContext = createContext(initialState);

interface Provider {
  children: ReactNode;
}

export const UserProvider = ({ children }: Provider) => {
  const [permissions, setPermissions] = useState<AppPermission[]>();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { isAuthenticated, logout } = useAuthentication();
  const { fetchPermissions } = useApi();
  const { showError, showAlert } = useAlert();

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);
        const data = (await fetchPermissions()).data;
        setPermissions(data);
        if (data.length === 0) {
          showAlert(t('errors.permissions.title'), t('errors.permissions.no_permission'), [
            {
              label: t('layout.navbar.logout'),
              handleClick: logout,
            },
          ]);
        }
      } catch (error) {
        showError(error);
      } finally {
        setLoading(false);
      }
    };
    if (isAuthenticated) {
      init();
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  return (
    <UserContext.Provider
      value={{
        permissions,
        loading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
