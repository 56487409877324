/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, ReactNode, useContext } from 'react';
import Axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { AppPermission } from '../types';
import { useAuthentication } from './authentication';

interface ApiContext {
  fetchPermissions: () => AxiosPromise<AppPermission[]>;
}

const initialState: ApiContext = {
  fetchPermissions: () => new Promise(() => {}),
};

const ApiContext = createContext(initialState);

export const ApiProvider = ({ children }: { children: ReactNode }) => {
  const { getToken } = useAuthentication();
  const rsApi = Axios.create({
    baseURL:
      (process.env.REACT_APP_API_URL && `${process.env.REACT_APP_API_URL}`) ||
      'https://api-flex-dev.arcosdorados.net/rs',
  });

  rsApi.interceptors.request.use(async (value: AxiosRequestConfig) => {
    const { accessToken, idToken } = await getToken();
    if (idToken && accessToken) {
      value.headers = {
        'access-token': accessToken,
        Authorization: `Bearer ${idToken}`,
        appId: 'tiles',
      };
      return value;
    } else throw Error('token-error');
  });

  const fetchPermissions = (): AxiosPromise<AppPermission[]> => rsApi.get('/permissions');

  return (
    <ApiContext.Provider
      value={{
        fetchPermissions,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export const useApi = () => useContext(ApiContext);
