import React, { useMemo } from 'react';
import { render } from 'react-dom';
import * as serviceWorker from './serviceWorker';
import Router from './Router';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { UserProvider } from './context/user';
import { useMediaQuery, createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { AlertProvider } from './context/alert';
import { AuthenticationProvider } from './context/authentication';
import { ApiProvider } from './context/api';

const App = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = useMemo(
    () =>
      createMuiTheme({
        palette: {
          primary: {
            main: '#009A61',
          },
          type: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode]
  );
  return (
    <React.StrictMode>
      <MuiThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          <AlertProvider>
            <AuthenticationProvider>
              <ApiProvider>
                <UserProvider>
                  <Router />
                </UserProvider>
              </ApiProvider>
            </AuthenticationProvider>
          </AlertProvider>
        </I18nextProvider>
      </MuiThemeProvider>
    </React.StrictMode>
  );
};

const root = document.getElementById('root');
if (root !== null) {
  render(<App />, root);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
