import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import es from './static/locales/es.json';
import en from './static/locales/en.json';
import pt from './static/locales/pt.json';
import fr from './static/locales/fr.json';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      es: {
        translation: es,
      },
      en: {
        translation: en,
      },
      pt: {
        translation: pt,
      },
      fr: {
        translation: fr,
      },
    },
    fallbackLng: 'es',
    nsSeparator: '.',
    debug: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      wait: true,
    },
  });

export default i18n;
