import React, { FunctionComponent } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import LazyWrapper from './components/shared/LazyWrapper';
import loadable from '@loadable/component';
import { useAuthentication } from './context/authentication';
import { useUser } from './context/user';
import LoadingPage from './components/shared/LoadingPage';

const Login = loadable(() => import('./components/login'));
const AppLayout = loadable(() => import('./components/layout'));

const Router = () => (
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <Switch>
      <Route exact path="/" component={LazyWrapper(Login)} />
      <PrivateRoute path={['/profile', '/launcher']} component={LazyWrapper(AppLayout)} />
      <Redirect path="*" to="/launcher" />
    </Switch>
  </BrowserRouter>
);

interface PrivateRouteProps {
  component: FunctionComponent;
  path: string | string[];
}

export const PrivateRoute = ({ component, path }: PrivateRouteProps) => {
  const { isAuthenticated, loading } = useAuthentication();
  const { loading: userLoading } = useUser();

  if (loading || userLoading) return <LoadingPage />;

  return isAuthenticated ? <Route path={path} component={component} /> : <Redirect to="/" />;
};

export default Router;
