import React, { Suspense } from 'react';
import LoadingPage from './LoadingPage';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { LoadableComponent } from '@loadable/component';

const ErrorFallback = ({ error }: FallbackProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClose = () => {
    history.push('/launcher');
  };

  return (
    <Dialog
      open
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClose={() => {}}
      aria-labelledby="error-dialog-title"
      aria-describedby="error-dialog-description"
    >
      <DialogTitle id="error-dialog-title">{`${t('errorBoundary.dialog.title')} ${
        error?.name
      }`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="error-dialog-description">
          {t('errorBoundary.dialog.description')}
          {error?.message}
        </DialogContentText>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('errorBoundary.dialog.backToLauncher')}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

const LazyWrapper = (WrapperComponent: LoadableComponent<unknown>) => (props: any) => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <Suspense fallback={<LoadingPage />}>
      <WrapperComponent {...props} />
    </Suspense>
  </ErrorBoundary>
);

export default LazyWrapper;
